import BasePage from './classes/base-page';

(function(window) {
  class Errors extends BasePage {
    constructor(window) {
      super();
    }
  }

  new Errors(window);
})(window);
